// Stylesheets
import './main.scss';

// Imports all component based scss files.
import './components/**/*.scss';

// import favicon here.
import './resources/images/favicon.png';

declare global {
  interface Window {
    Bus: any; // event bus
    OneTrust: any;
    _tnsInstances: any; //carousel
    _tabbyInstances: any; //tabs
    _plyrInstances: any; //vimeo embed
    _badgerInstances: any; //accordion
  }
}

(() => {
  // hamburger menu click callback
  const handleHamburger = isOpened => {
    if (isOpened) {
      document.body.classList.add('menu-open');
    } else {
      document.body.classList.remove('menu-open');
    }
  };

  // when page is scrolled up, the menu should show up as a fixed element
  // when page is scrolled down, the menu should get hidden
  const handleStickyMenu = () => {
    let scrollPos = window.scrollY;

    if (scrollPos > 0) {
      document.body.classList.add('has-sticky-header');
    }

    //header scroll event listener
    window.addEventListener('scroll', function () {
      const curScrollPos = window.scrollY;

      if (curScrollPos === 0) {
        document.body.classList.remove(
          'has-sticky-header',
          'show-sticky-header'
        );
      } else {
        document.body.classList.add('has-sticky-header');
        if (curScrollPos < scrollPos) {
          document.body.classList.add('show-sticky-header');
        } else {
          document.body.classList.remove('show-sticky-header');
        }
      }

      scrollPos = curScrollPos;
    });
  };

  const handlePageUrlWithHash = () => {
    const pageHash = window.location.hash;
    if (pageHash) {
      const el = document.querySelector(pageHash);
      if (el) {
        window.addEventListener('load', () => {
          el.scrollIntoView();
          history.replaceState(
            '',
            document.title,
            window.location.pathname + window.location.search
          );
        });
      }
    }
  };

  // when any anchor on the page is clicked, if there is an element on the page with that specific href as id, scroll the page to that section
  const handlePageAnchors = () => {
    // selecting all anchors that has hash in hrefs. But avoiding tabs
    const allAnchors = document.querySelectorAll(
      'a[href*="#"]:not([role="tab"])'
    ) as NodeListOf<HTMLAnchorElement>;
    const menuTrigger = document.querySelector(
      '.header__menu-trigger'
    ) as HTMLButtonElement;

    allAnchors.forEach(el => {
      const href = el.getAttribute('href') || '';
      const hash = href?.split('#')[1];

      if (hash) {
        let section = document.querySelector(`#${hash}`) as HTMLElement;

        if (section) {
          el.addEventListener('click', e => {
            const isMobileView = window.matchMedia(
              '(max-width: 1023px)'
            ).matches;
            e.preventDefault();
            if (
              isMobileView &&
              menuTrigger &&
              document.body.classList.contains('menu-open')
            ) {
              menuTrigger?.click?.();
            }
            section.scrollIntoView();

            // timeout needed so that the show sticky header class will be removed only after th page scroll is done
            const timer = setTimeout(() => {
              document.body.classList.remove('show-sticky-header');
              clearTimeout(timer);
            }, 900);
          });
        }
      }
    });
  };

  //when form is accepted scroll to top of the container

  const listenToFormSuccessEvent = () => {
    const formElem = document.querySelector('.ajaxContainer') as HTMLElement;
    if (formElem) {
      const formTop = document.querySelector(
        '.form-container__top'
      ) as HTMLElement;

      const observer = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
          if (mutation.attributeName == 'class') {
            if (formElem.classList.contains('js-response-success')) {
              formTop.scrollIntoView();
            }
          }
        });
      });

      observer.observe(formElem, {
        attributes: true,
        attributeFilter: ['class'],
      });
    }
  };

  const init = () => {
    handleStickyMenu();
    handlePageUrlWithHash();
    handlePageAnchors();
    listenToFormSuccessEvent();

    if (window.Bus) {
      // listen to events here
      window.Bus.on('emu-button:click', val => {
        const { title, toggleOn } = val;
        if (title === 'menu-trigger') {
          handleHamburger(toggleOn);
        }
      });
    }
  };
  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
